import React from "react";

const FileUpload: React.FC<{
  disableUploads: boolean;
  file: File | null;
  onFileSelect: (file: File | null) => void;
  uploadDbtFile: () => void;
}> = ({ disableUploads, file, onFileSelect, uploadDbtFile }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      onFileSelect(selectedFile);
    } else {
      console.log("Error selecting files, please see logs");
      onFileSelect(null);
    }
  };

  // Some uniform logic
  const disabledBtnCss = "bg-gray-400 cursor-not-allowed";

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Upload File</h2>
      <div className="h-full border border-gray-600 rounded p-6 m-6">
        {file ? (
          <p>Selected File: {file.name}</p>
        ) : (
          <p>No file uploaded yet.</p>
        )}
      </div>
      <label
        className={`m-4 text-white font-semibold py-2 px-4 rounded cursor-pointer transition duration-200 inline-block ${
          disableUploads ? disabledBtnCss : "bg-secondary hover:bg-blue-700"
        }`}
      >
        {file ? "Change File" : "Choose File"}
        <input
          type="file"
          onChange={handleFileChange}
          className="hidden"
          disabled={disableUploads}
        />
      </label>
      <button
        className={`m-4 text-white font-semibold py-2 px-4 rounded transition duration-200 inline-block ${
          file === null || disableUploads
            ? disabledBtnCss
            : "bg-secondary hover:bg-blue-700"
        }`}
        disabled={file === null || disableUploads}
        onClick={uploadDbtFile}
      >
        Process dbt Project
      </button>
    </div>
  );
};

export default FileUpload;
