import React from "react";
import getDbtModels from "../lib/getDbtModels";
import { GetDbtModelsResponseData } from "../types/dbtModels";

export type FileInteractionsProps = {
  uniqueFileUid: string;
  handleProcessingOutput: (output: GetDbtModelsResponseData) => void;
};

const FileInteractions: React.FC<FileInteractionsProps> = ({
  uniqueFileUid,
  handleProcessingOutput,
}) => {
  const showDbtModels = async () => {
    const models = await getDbtModels(uniqueFileUid);
    if (models != null) {
      handleProcessingOutput(models);
    } else {
      console.log("Error getting dbt model data. Please see logs.");
    }
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">
        What do you want to do with the DBT project?
      </h2>

      <button
        className="m-4 text-white font-semibold py-2 px-4 rounded transition duration-200 inline-block bg-secondary hover:bg-blue-700"
        onClick={showDbtModels}
      >
        Visualize Models
      </button>
    </div>
  );
};

export default FileInteractions;
