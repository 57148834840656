import React, { useMemo } from "react";
import ReactFlow, {
  Controls,
  Background,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
} from "reactflow";
import "reactflow/dist/style.css";
import { GetDbtModelsResponseData } from "../types/dbtModels";

export type ERDProps = {
  entities: GetDbtModelsResponseData;
};

const ERD: React.FC<ERDProps> = ({ entities }) => {
  // Generate nodes for React Flow
  const initialNodes = useMemo(() => {
    return entities.models.map((model, index) => ({
      id: model, // Use the entity name as the ID for consistency
      type: "default",
      data: { label: model },
      position: { x: Math.random() * 400, y: Math.random() * 400 },
      draggable: true,
    }));
  }, [entities.models]);

  // Generate edges for React Flow
  const initialEdges = useMemo(() => {
    const edges: any[] = [];

    // Loop through each model in the dependencies object
    Object.entries(entities.dependencies).forEach(([model, dependencies]) => {
      dependencies.forEach((dependency) => {
        edges.push({
          id: `edge-${model}-${dependency}`,
          source: dependency, // The dependency is the source
          target: model, // The model is the target
          type: "default", // You can customize edge types if needed
          animated: true, // Optional: animate the edges
        });
      });
    });

    return edges;
  }, [entities.dependencies]);

  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, , onEdgesChange] = useEdgesState(initialEdges);

  return (
    <div className="flex-grow w-full border border-gray-300 rounded p-4">
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
        >
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

export default ERD;
