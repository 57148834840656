import { UploadDbtModelsResponse } from "../types/dbtModels";

const uploadDbtProject = async (file: File) : Promise<string | null> => {
    const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable

    // Create form data for submission to BE
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(`${apiUrl}/data`, {
          method: 'POST',
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error('File upload failed.');
        }
  
        const data : UploadDbtModelsResponse = await response.json();
        console.log("File upload successful!");
        return data.unique_id;
      } catch (error) {
        console.log(`File upload Failed. Error: ${error}`)
        return null
      }
}

export default uploadDbtProject;

