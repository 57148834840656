import { GetDbtModelsResponse, GetDbtModelsResponseData } from "../types/dbtModels";

const getDbtModels = async (unique_id: string) : Promise<GetDbtModelsResponseData | null> => {
    const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable

    try {
        const response = await fetch(`${apiUrl}/models/${unique_id}`, {
          method: 'POST',
        });
  
        if (!response.ok) {
          throw new Error('DBT Model get failed.');
        }
  
        const data : GetDbtModelsResponse = await response.json();
        console.log("Model metadata fetch successful!");
        return { models: data.models, dependencies: data.model_dependencies};
      } catch (error) {
        console.log(`Model metadata fetch Failed. Error: ${error}`)
        return null
      }
}

export default getDbtModels;

