import React, { useState } from "react";
import FileUpload from "./FileUpload";
import OutputDisplay from "./OutputDisplay";
import FileInteractions from "./FileInteractions";
import uploadDbtProject from "../lib/uploadDbtProject";
import { GetDbtModelsResponseData } from "../types/dbtModels";

const Layout: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [disableUploads, setDisableUploads] = useState<boolean>(false);
  const [uniqueFileUid, setUniqueFileUid] = useState<string | null>(null);
  const [processingOutput, setProcessingOutput] =
    useState<GetDbtModelsResponseData | null>(null);

  const handleFileSelect = (selectedFile: File | null) => {
    setFile(selectedFile);
  };

  const handleProcessingOutput = (output: any) => {
    setProcessingOutput(output);
  };

  const uploadDbtFile = async () => {
    if (file === null) {
      // TODO - Make better
      console.log("Error - File is none.");
    } else {
      const uniqueId = await uploadDbtProject(file);
      if (uniqueId != null) {
        setUniqueFileUid(uniqueId);
        setDisableUploads(true);
      }
    }
  };

  return (
    <div className="flex h-screen dark:bg-primary dark:text-white">
      <div className="flex h-screen flex-col w-1/2 border-r border-gray-600">
        <div className="h-1/2 p-6">
          <FileUpload
            disableUploads={disableUploads}
            file={file}
            onFileSelect={handleFileSelect}
            uploadDbtFile={uploadDbtFile}
          />
        </div>
        {uniqueFileUid != null && (
          <div className="h-1/2 p-6">
            <FileInteractions
              uniqueFileUid={uniqueFileUid}
              handleProcessingOutput={handleProcessingOutput}
            />
          </div>
        )}
      </div>

      <div className="w-1/2 p-6 h-screen flex flex-col">
        {processingOutput != null && (
          <OutputDisplay
            uniqueFileUid={uniqueFileUid}
            output={processingOutput}
          />
        )}
      </div>
    </div>
  );
};

export default Layout;
