import React from "react";
import ERD from "./ERD";
import { GetDbtModelsResponseData } from "../types/dbtModels";

export type OutputDisplayProps = {
  uniqueFileUid: string | null;
  output: GetDbtModelsResponseData;
};

const OutputDisplay: React.FC<OutputDisplayProps> = ({
  uniqueFileUid,
  output,
}) => {
  return (
    <div className="flex flex-grow flex-col">
      <h2 className="text-xl font-bold mb-4">Results</h2>
      {uniqueFileUid && (
        <p>Visualizing dbt project with UUID: {uniqueFileUid}</p>
      )}{" "}
      {output && <ERD entities={output} />}
    </div>
  );
};

export default OutputDisplay;
